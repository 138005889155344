/* html {
  overflow: scroll;
  scroll-behavior: smooth;
}
*/
.target-date-modal {
  height: 323px;

}
.target-modal{
  max-height: 323px;
  max-width: 400px !important;
}
.modal-label {
  display: inline-block;
  margin-bottom: 0.1rem;
  position: relative;
  top: 8px;
  right: 0px;
  white-space: nowrap;
}
.modal-label-sub {
  display: inline-block;
  margin-bottom: 0.1rem;
  position: relative;
  top: 8px;
  right: -20px;
  white-space: nowrap;
}
.date{
  position: relative;
  right: -103px;

}
.crossbtn{
  position: relative;
  right: 0px;
}

#savebtn{
  position: relative;
  right: -100px;
}


#closebtn{
  position: relative;
  right: -110px;
}

