/* html {
  overflow: scroll;
  scroll-behavior: smooth;
}
*/
/* html {
  scroll-behavior: smooth;
} */
.font-sm-custom{
font-size:10px; 
display: block !important;
}
::-webkit-scrollbar {
  width: 0; 
  height: 5px;
  /* max-height: 0;  */
} 
.pgc-bg {
  min-height: 100%;
}
.pgc-bg-color {
  width: 100%;
  width: fit-content;
}
.pgc-text-color {
  color: #c9c9c9;
}
.borderMainheader {
  border-bottom: 6px solid rgb(93 90 90) !important;
}
.pgc-mainheader {
  color: #c9c9c9;
  display: flex;
  /* justify-content: center; */
  position: fixed;
  top: 45px;
  width: 100%;
  z-index: 100;
  background-color: black;
  margin-top: -45px;
  border-bottom: 6px solid rgb(255 255 255 / 64%);
}
.pgc-mainheader label {
  color: #ffffff;
  font-size: 1.6em;

  margin: 0px;
  margin-top: 17px;
}
.pgc-mainheader .mainheader {
  font-weight: 600 !important;
}
.hidden {
  visibility: hidden;
}
.pg-entitytag {
  float: left;
  width: 60px !important;
  text-align: center;
  border-radius: 2px;
  color: #e1e1e1;
  font-size: xx-small;
  margin-right: 8px !important;
  border: 1px solid;
  color: #ababab !important;
  font-weight: 500;
}

.pg-entitytag-task {
  font-size: 1.1em;
  color: white;
  width: 220px;
  float: left;
  margin: 0px;
  font-weight: 500;
}
.pg-entitytag-green {
  background-color: #47c847;
  border-color: #47c847 !important;
  color: white !important;
}
.pg-entitytag-orange {
  background-color: #b56f1c;
  border-color: #b56f1c !important;
  color: white !important;
}
.pg-entitytag-blue {
  background-color: #4398c4;
  border-color: #4398c4 !important;
  color: white !important;
}
.pg-entitytag-goldenyellow {
  border-color: #d1bb0e !important;
  background-color: #b5a21c;
  color: white !important;
}

.pg-text-amber {
  color: #c79500;
}
.pg-text-green {
  color: #28a745;
}
.pg-text-blue {
  color: #85a2f3;
}
.pg-text-red {
  color: #d9534f;
}

.pg-bg-amber {
  background: #c79500;
}
.pg-bg-green {
  background: #28a745;
}
.pg-bg-blue {
  background: #85a2f3;
}
.pg-bg-red {
  background: #d9534f;
}

.pg-progress {
  text-align: center;
  display: block;
  font-size: 1.1em;
  margin: 0px;
  font-weight: 500;
}

.pgc-progress_green {
  color: #5fc275;
}

.pgc-progress_amber {
  color: #c79500;
}

.pgc-progress_blue {
  color: #499ae8;
}

.pgc-progress_red {
  color: #ea5e59;
}

.pgc-header-activity {
  font-weight: bold;
  color: white;
  padding: 5px 0px 0px 0px;
  margin: 0px;
}

.header {
  color: #202125;
  margin-bottom: 40px;
}
.header h2 {
  font-weight: 600;
}
.header p {
  font-weight: 300;
}
.wrapper {
  max-width: 1200px;
  min-width: 700px;
  margin: 0 auto;
  padding: 40px;
}
.gantt {
  display: block;
  border: 0;
  position: relative;
  box-sizing: border-box;
  margin-top: 11px;
}

.gantt-upper-border {
  position: sticky;
  z-index: 100;
  top: 56px;
  background-color: black;
}
.gantt__row {
  display: flex;
}
.gantt__row:nth-child(odd) {
}
.gantt__row:nth-child(odd) .gantt__row-first {
}
.gantt__row:nth-child(3) .gantt__row-bars {
  border-top: 0;
}
.gantt__row:nth-child(3) .gantt__row-first {
  border-top: 0;
}
.gantt__row--empty {
  z-index: 1;
}
.gantt__row--empty .gantt__row-first {
  border-width: 1px 1px 0 0;
}
.gantt__row--lines {
  position: absolute;
  height: 97%;
  width: 100%;
  background-color: transparent;
  grid-template-columns: 28px repeat(12, 1fr);
}
.gantt__row--lines span {
  display: block;
  width: 90px;
  border-right: 1px dotted #5d5d5d;
  z-index: -1;
}

.gantt__row--lines label {
  display: block;
  border-right: 2px solid rgb(255 242 242 / 61%);
  position: sticky;
  left: 0px;
  z-index: 1;
  margin: 0px;
}

.gantt__row--lines span.marker {
  z-index: 2;
}
.gantt__row--lines:after {
  grid-row: 1;
  grid-column: 0;
  background-color: #1688b3 45;
  z-index: 2;
  height: 100%;
}
.gantt__row--months {
  color: #fff;
  border-bottom: 2px solid rgb(255 242 242 / 61%);
  position: sticky;
  top: 61px;
  width: 100%;
  z-index: 1;
  background-color: #212529;
}
.gantt__row--months .gantt__row-first {
  border-top: 0 !important;
}

.gantt__row--months span {
  text-align: center;
  font-size: 11px;
  align-self: center;
  font-weight: bold;
  padding: 10px 0;
  width: 90px;
}
.gantt__row-first {
  margin: 0px;
  border-width: 1px 0 0 0;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 5px 5px;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  position: sticky;
  left: -1px;
  min-width: 280px;
  max-width: 280px;
  background-color: #212529;
  display: inline-block;
}

.gantt__row-bars {
  padding: 10px 0px 0px 0px;
  margin: 0;
  width: 100%;
}
.gantt__row-bars li {
  padding: 6px 0px;
  overflow: hidden;
  border-radius: 2px;
  height: 1px;
  background-color: #bbbbbb;
  box-shadow: inset -1px -4px 5px 2px rgb(0 0 0 / 23%),
    2px 2px 6px 1px rgb(0 0 0 / 44%);
}
.gantt__row-bars li.progresscompleted {
  background-color: #1b9d10;
}

.gantt__row-bars li.stripes_progress_amber {
  background-color: #c79500;
}

.gantt__row-bars li.stripes_progress_green {
  background-color: #1b9d10;
}

.gantt__row-bars li.stripes_progress_blue {
  background-color: #06a1d7;
}

.gantt__row-bars li.stripes_progress_red {
  background-color: #de140e;
}

.ninetyprogress {
  float: right;
  margin: 0px;
  margin-top: -12px;
  border-radius: 0px 2px 2px 0px !important;
  box-shadow: none !important;
}
.ninetyprogress_progress_green {
  background: #84c57f !important;
}

.ninetyprogress_progress_amber {
  background: #efd896 !important;
  border: 1px solid #c79500;
}

.ninetyprogress_progress_blue {
  background: #8ccee6 !important;
  border: 1px solid #06a1d7;
}

.ninetyprogress_progress_red {
  background: #f1aeac !important;
  border: 1px dashed #de140e;
}

.gantt__row-bars li:before,
.gantt__row-bars li:after {
  content: "";
  height: 100%;
  top: 0;
  z-index: 4;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}
.gantt__row-bars li:before {
  left: 0;
}
.gantt__row-bars li:after {
  right: 0;
}

.currentMonthStyleTitle {
  background-color: #0e92c4c2;
  border: 1px solid rgb(75 132 153) !important;
}

.currentMonthStyleBar {
  background-color: #1688b340;
  border: 1px solid #34a9d68c !important;
}

.mainFirstActivity {
  border-bottom: 1px solid grey !important;
  padding-top: 10px;
}

.switch {
  padding: 5px 10px;
  display: inline-block;
  width: 50px;
  height: 23px;
  position: absolute;
  top: 45%;
  right: 302px;
  transform: translateY(0%);
  margin: 0px !important;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 6px;
  bottom: 3.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.as-on {
  justify-content: right !important;
  border-radius: 44px;
  position: absolute;
  top: 27%;
  left: 2px;
  transform: translateY(26%);
  font-size: 11px !important;
  font-weight: bold !important;
}
.as-on-date {
  justify-content: right !important;
  border-radius: 44px;
  position: absolute;
  top: 27%;
  left: 39px;
  transform: translateY(26%);
  font-size: 11px !important;
  font-weight: bold !important;
}
.mainheader-buttons {
  background-color: gray;
  justify-content: right !important;
  border-radius: 44px;
  position: absolute;
  font-size: 10px;
  cursor: pointer;
  color: white;
  top: 43% !important;
  width: 69px !important;
  margin: 0px !important;
  border: 1px solid #939393;
}
.download {
  right: 79px;
}

.downloading {
  display: none;
  background-color: gray;
  right: -480px;
  justify-content: right !important;
  border-radius: 44px;
  position: absolute;
  top: 45%;
  right: 2px;
  cursor: pointer;
  color: white;
  transform: translateY(26%);
}

.back {
  right: 5px;
}
.legends {
  right: 153px;
}
.zoominbtn {
  right: 227px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 143px;
  top: 56px;
  border-radius: 9px;
  padding: 3px;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  cursor: pointer;
}
.input-color .color-box2 {
  width: 20px !important;
  height: 20px !important;
  display: inline-block !important;
  background-color: #ccc;
  position: absolute !important;
  left: 4px !important;
  top: 12px !important;
  border-radius: 4px;
}
.labelChange {
  color: black !important;
  font-size: 0.9em !important;
  display: inline;
  position: relative;
  right: -4px;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.mti-gantt {
  position: relative;
  top: -16px;
}

.goLiveDiv{
  position: absolute;
  height: 97%;
  width: 100%;
  float: right;
}

.goLiveSpan{
  border-right: 4px solid #39b039e8;
  box-shadow: 0 0 13px 1px #e4e78a73;
    float: right;
    height: 100%;
    /* margin-right: 10px; */
}

/* .mtisbheader{

  padding-top: 50px;
} */