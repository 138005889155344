.custom-main-header{
    user-select: none;
    z-index: 110;
    position: fixed;
    width: 100%;
    padding-top: 5px;
    background-image: linear-gradient(to right, #4cadd2, #1a75b5);
}
.custom-header-title{
  /* font-family: '"Raleway", sans-serif'; */
  padding: 20px;
  padding-right: 10px;
  padding-top: 12px;
  font-Weight: 600;
  color: 'white';
  cursor: 'pointer'
}
.custom-header-height{height:50px;
                      font-size: large;} /*1rem = 16px*/

.custom-before-header{padding-top: 50px !important
                      }

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (max-width: 992px) {
  .custom-header-height {height:50px;font-size: large}
}
@media screen and (max-width: 767.98px){
  .custom-header-height {display: none;}
  .custom-before-header {padding-top: 40px !important}
  .header-mobile {display: block !important;
                  height: 40px !important;}
}

.header-mobile{
  display: none;;
}

.header-user-font{
  font-size: 14px !important;
  left: 4% !important;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  .custom-header-height {height:8%;font-size: large}
} */

.logout-btn{
  float: right;
  margin-right: 18px;
  margin-top: -32px;
}
.home-btn{
  float: right;
  margin-right: 87px;
 
}
.hometn{
  width: 61px;
}

