.cardbox_custom{
  min-width: 15rem;
  border-radius: 15px 15px 15px 0px;
  height: 10.7rem;
}

.cardbox_green{
    border-color: #9bbb59;
    border-width: 2px;
    /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(241, 255, 222, 1) 0%, rgba(221, 253, 176, 1) 100%); 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(241, 255, 222, 1) 0%, rgba(221, 253, 176, 1) 100%);
    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(241, 255, 222, 1) 0%, rgba(221, 253, 176, 1) 100%);
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#DDFDB0', endColorstr='#F1FFDE', GradientType=1 );
    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(241, 255, 222, 1) 0%, rgba(221, 253, 176, 1) 100%);
    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(241, 255, 222, 1) 0%, rgba(221, 253, 176, 1) 100%);
}
.cardbox_blue{
  border-color: #4bacc6 ;
  border-width: 2px;
  /* ff 3.6+ */
  background:-moz-linear-gradient(180deg, rgba(217, 246, 255, 1) 0%, rgba(162, 235, 255, 1) 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(180deg, rgba(217, 246, 255, 1) 0%, rgba(162, 235, 255, 1) 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(180deg, rgba(217, 246, 255, 1) 0%, rgba(162, 235, 255, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#A2EBFF', endColorstr='#D9F6FF', GradientType=1 );

  /* ie 10+ */
  background:-ms-linear-gradient(180deg, rgba(217, 246, 255, 1) 0%, rgba(162, 235, 255, 1) 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(180deg, rgba(217, 246, 255, 1) 0%, rgba(162, 235, 255, 1) 100%);

}
.cardbox_red{
  background-color: #d55353;
  border-width: 2px;
  /* ff 3.6+ */
  background:-moz-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFA3A2', endColorstr='#FFDEDE', GradientType=1 );

  /* ie 10+ */
  background:-ms-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

}
.cardbox_amber{
  background-color: #c79500;
  border-width: 2px;
  /* ff 3.6+ */
  background:-moz-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFA3A2', endColorstr='#FFDEDE', GradientType=1 );

  /* ie 10+ */
  background:-ms-linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(180deg, rgba(255, 222, 222, 1) 0%, rgba(255, 163, 162, 1) 100%);

}

.progress_red{
  background-color: #d55353 !important;
}
.progressborder_red{
  border: 2px solid #db2c2c !important;
  border-radius: 0px !important;
}
.progress_blue{
  background-color: #007bffb0 !important;
}
.progressborder_blue{
  border: 2px solid #357d91 !important;
  border-radius: 0px !important;
}
.progress_green{
    background-color: #85ccb1 !important;
}
.progressborder_green{
    border: 2px solid #85ccb1 !important;
    border-radius: 0px !important;
}

.progress_amber{
  background-color: #c79500 !important;
}
.progressborder_amber{
  border: 2px solid #c79500 !important;
  border-radius: 0px !important;
}




.tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
.custom-Shadow{
    /* box-shadow: 0 .2rem 1rem rgba(0,0,0,.15)!important; */
    -webkit-box-shadow: 2px 5px 5px 1px #959292cc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    2px 5px 5px 1px #959292cc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         2px 5px 5px 1px #959292cc;
}
.custom-Shadow:hover {
  box-shadow: 0 0.3em 0.5em -0.1em;
  transform: translateY(-0.05em);
}

.custom-card-head{font-size:0.9rem;} /*1rem = 16px*/

/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  
  custom-card-head {font-size:1.5rem;} /*1rem = 16px*/
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  custom-card-head {font-size:2rem;} /*1rem = 16px*/
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  custom-card-head {font-size:2.5rem;} /*1rem = 16px*/
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  custom-card-head {font-size:3rem;} 
}

.box-header{
    color: #1d1c1c !important;
    font-weight: 500;
}
.heading-height{
  height: 36px;
}

.text-grey1{

  color:#7f7777 !important;
}

.text-size66{
  font-size: 66% !important;
}
.text-size65{
  font-size: 65% !important;
}

.text-size11{
  font-size: 11px;
}

.card-background{

  /* ff 3.6+ */
  background:-moz-linear-gradient(258deg, rgba(226, 227, 227, 1) 0%, rgba(230, 228, 230, 0.44) 99%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(258deg, rgba(226, 227, 227, 1) 0%, rgba(230, 228, 230, 0.44) 99%);

  /* opera 11.10+ */
  background:-o-linear-gradient(258deg, rgba(226, 227, 227, 1) 0%, rgba(230, 228, 230, 0.44) 99%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E2E3E3', endColorstr='#E6E4E6', GradientType=0 );

  /* ie 10+ */
  background:-ms-linear-gradient(258deg, rgba(226, 227, 227, 1) 0%, rgba(230, 228, 230, 0.44) 99%);

  /* global 94%+ browsers support */
  background:linear-gradient(258deg, rgba(226, 227, 227, 1) 0%, rgba(230, 228, 230, 0.44) 99%);

}

.green-bar-border{
  border : solid 1px #85ccb1
}

.blue-bar-border{
  border : solid 1px #007bffb0
}
.red-bar-border{
  border : solid 1px #d9534f
}

.amber-bar-border{
  border : solid 1px #c79500
}




.hover_color_bubble {
  border-radius: 50%;
  height: 65px;
  width: 68px !important;
  position: relative;
}

.top-right{
  position: absolute;
  top: 0;
  right: 0;
 
}
.side-vl{
  
  padding-right: 5px;
  right: -6px;
}
.side-vl.border-green{
  border-right: 4px solid #85ccb1;
}
.side-vl.border-blue{
  border-right: 4px solid #007bffb0;
}
.side-vl.border-red{
  border-right: 4px solid #d9534f;
}

.hover_color_bubble.bubble_green{
  border: 4px solid #85ccb1;
}
.hover_color_bubble.bubble_blue{
  border: 4px solid #007bffb0;
}
.hover_color_bubble.bubble_red{
  border: 4px solid #d9534f;
}

.hover_color_bubble.bubble_amber{
  border: 4px solid #c79500;
}

/* .center {
  margin: auto;
  text-align: center !important
} */
center {
  margin: auto;
  padding-bottom: 0px;
  padding-left: 18px;
  padding-top: 3px;
}

.button-pos {
  bottom: 9px;
  left: 11px;
}
.vl {
  border-left: 6px solid green;
  height: 500px;
}

.weight-class{
  font-size: 15px;
  position: absolute; 
  bottom: -4px; 
  color:#c2c4c6;
}

.border-amber{
  border-right: 4px solid #c79500;
}
